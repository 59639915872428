import Layout from 'layout/index';
import React, { useMemo } from 'react';
import { Favorites } from 'sections/Favorites/Favorites';
import { SingleFlatType } from 'types/CommonTypes';
import { getDisabledPages } from 'utils/utils';

interface FavoritesPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: FavoritesPageProps) => {
    const dataMap = useMemo(() => {
        return pageContext?.data?.allWpInvestment?.edges?.map((item: any) => ({
            ...item.node.investmentFlat,
            buildingFloor: item.node.investmentFlat.buildingFloor.name,
            flatAmenities: item.node.investmentFlat.flatAmenities?.name,
            flatStatus: item.node.investmentFlat.flatStatus?.name,
            investmentBuilding:
                item.node.investmentFlat.investmentBuilding.name,
            priceMeter: Math.round(
                item.node.investmentFlat.flatPrice /
                    item.node.investmentFlat.flatArea,
            ),
        }));
    }, [pageContext?.data?.allWpInvestment?.edges]);

    const sortableData: SingleFlatType[] = dataMap?.filter(
        (item: SingleFlatType) => !item?.flatFeatured,
    );
    const noSortableData: SingleFlatType[] = dataMap?.filter(
        (item: SingleFlatType) => item?.flatFeatured,
    );
    const parsedDataTable = noSortableData?.concat(sortableData);

    const pageTitle = `${pageContext?.title} - Ulubione mieszkania`;

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            displayBanner
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            {parsedDataTable && (
                <Favorites
                    investmentSlug={pageContext?.slug}
                    data={parsedDataTable}
                />
            )}
        </Layout>
    );
};
